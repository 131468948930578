import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Spacer } from 'components/utils';
import Main from './Main';
import Recommendations from './Recommendations';
import Predispositions from './Predispositions';
import Handbrakes from './Handbrakes';
import { IWeightManagementReport } from 'api/reports';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  margin-top: ${isMobile ? '0' : '0px'};
  @media print {    
    margin-top: 30px;
`;

interface ReportBodyProps {
  results: IWeightManagementReport;
  useName:boolean;
  title:boolean;
  borders:boolean;
}

const ReportBody = ({ results, useName=true, title=true, borders=true }: ReportBodyProps) => {
  const location = useLocation();
  return (
    <Wrapper>
      <Main results={results} useName={useName} title={title} borders={borders}/>
      {location.pathname === '/reports/weight-management/' ||
      location.pathname === '/reports/weight-management' ? (
        <div>
          <Spacer height={'30px'} />
          {results && <Recommendations results={results} />}
          <Spacer height={'30px'} />
          {results && <Predispositions results={results} />}
          <Spacer height={'30px'} />
          {results && <Handbrakes results={results} />}
          <Spacer height={'30px'} />
        </div>
      ) : (
        ''
      )}

      {/* <Row>
        <Col xs={12} md={12}>
          <ButtonFull
            variant="primary"
            href="https://myingeneous.com/supplement-retailers/"
            target="_blank"
          >
            <ImageButton
              src="/assets/images/recommendations/Supplement.png"
              alt="supplement"
              width={'18%'}
            />
            Find Supplements
          </ButtonFull>
        </Col>
        <Col xs={12} md={6}>
          <ButtonFull variant="primary">
            <ImageButton
              src="/assets/images/recommendations/FindPractitioner.png"
              alt="practitioner"
              width={'17%'}
            />
            Find Practitioner
          </ButtonFull>
        </Col>
      </Row> */}
      {/* <WhatsNext /> */}
    </Wrapper>
  );
};

export default ReportBody;