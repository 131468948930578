/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { getLifePlus } from 'api/reports';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import SearchForm from '../components/SearchForm';
import Results from './components/Results';

const SearchWrapper = styled.div`
  h4 {
    color: #707070;
  }
`;

const OffsetCol = styled(Col)`
  margin-top: -110px;
  z-index: 9999;
`;

const OffsetWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #dedede;
`;

const Alert = styled.div`
  color: #e79b43;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  background-color: #f1e5d9;
  border-radius: 5px;
`;

const LifePlus = () => {
  const [kitId, setKitId] = useState(localStorage.getItem('consultKitId'));
  const [results, setResults] = useState();
  const [reportStatus, setReportStatus] = useState('loading');
  const [searchLoading, setSearchLoading] = useState(false)

  const handleSearch = (data) => {
    localStorage.setItem('consultKitId', data);
    setKitId(data);
  };

  const reportResult = () => {
    if(reportStatus === 'pending'){
      return (
        <Alert>Report is pending lab results</Alert>
      )
    }

    if(reportStatus === 'complete' && results !== undefined){
      return (<Results results={results} />)
    }

    if(reportStatus === 'loading'){
      return <Alert>Loading results...</Alert>
    }

    if(searchLoading){
      return <Alert>Loading results...</Alert>
    }

  }

  useEffect(() => {
    if (kitId) {
      getLifePlus(kitId).then((res) => {
        setReportStatus(res.data.status)
        if (res.data.status === 'complete') {
          setResults(res.data);
        }
      });
    }
  }, []);

  if (!kitId) {
    return <Redirect to={'/consult'} />;
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{user?.display_name}</h3> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>
        <PageBody>
          <Container className="practitioner">
            <Row>
              <Col xs={12} md={3}>
                <Spacer height="50px" />
                <SearchWrapper>
                  <h4>Consult Support</h4>
                  <SearchForm handleSearch={handleSearch} query={kitId} handleLoading={setSearchLoading}/>
                </SearchWrapper>
              </Col>
              <OffsetCol md={9}>
                <OffsetWrapper>
                  <Row>
                    <Col>
                      <h2>LifeStyle Plus Results</h2>
                      <Spacer height={'20px'} />
                      {reportResult()}
                    </Col>
                  </Row>
                </OffsetWrapper>
              </OffsetCol>
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default LifePlus;
