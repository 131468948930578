/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { AuthContext } from 'stores/auth';
import { artilectLogin } from 'api/practitioner';
import { Spacer } from 'components/utils';
import { Page, PageBody } from 'components/Page';

import { getAuth, signInWithCustomToken } from 'firebase/auth';

const Artilect = () => {
  const auth = getAuth();
  const authStore = useContext(AuthContext);
  let params = useParams();
  let history = useHistory();

  useEffect(() => {
    artilectLogin(params?.token, 'practitioner').then((res) => {
      signInWithCustomToken(auth, res.data?.access)
        .then((userCredential) => {
          const user = userCredential.user;
          authStore.loginArtilect(user).then(() => {
            localStorage.setItem('consultKitId', res.data?.sample_id?.code);
            history.push('/consult/methylation');
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  return (
    <>
      <Page>
        <PageBody>
          <Container className="artilect">
            <Row>
              <Col xs={12} md={6}>
                &nbsp;
              </Col>
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default Artilect;
