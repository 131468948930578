import React, { useContext, useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { borderGray, alert, primary, gray } from 'styles/theme';
import ReadMore from './ReadMore';
import ReadLess from './ReadLess';
import { useLocation } from 'react-router-dom';
import { IWeightManagementReport } from 'api/reports';
import useDetectPrint from 'react-detect-print';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '20px 10px' : '30px'};

  @media print {
    border: none;
  }
`;

const BorderlessWrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  padding: ${isMobile ? '20px 10px' : '30px'};
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  display: ${isMobile ? 'flex' : 'inherit'};
  justify-content: ${isMobile ? 'center' : 'unset'};
`;

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid ${alert};
  span {
    color: ${alert};
    text-transform: uppercase;
    font-size: 0.8rem;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const BoldSpanGreen = styled.span`
  font-weight: bold;
  color: ${primary};
`;

const ListGreen = styled.ul`
  font-weight: bold;
  color: ${primary};
  padding-left: 10px;
`;

const WrapperProgram = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  p {
    padding-right: 20px;
  }
  div {
    display: ${isMobile ? 'flex' : 'inherit'};
    justify-content: ${isMobile ? 'space-evenly' : 'inherit'};
  }
  .repeat-img {
    align-self: center;
    margin-bottom: ${isMobile ? 0 : '20px'};
    margin-left: ${isMobile ? 0 : '10px'};
  }
`;

const Subtitle = styled.h4`
  color: ${gray};
  margin-left: ${isMobile ? '20px' : '10px'};
`;

const SpanBold = styled.span`
  font-weight: 600;
`;

const SpanGreen = styled.span`
  color: ${primary};
`;

const PrintCol = styled(Col)`
  @media print {
    page-break-after: always;
  }
`;

const PrintRow = styled(Row)`
  @media print {
    page-break-after: always;
    margin-top: 50px;
  }
`;

interface MainProps {
  results: IWeightManagementReport;
  useName:boolean;
  title:boolean;
  borders:boolean
}
// eslint-disable-next-line
const Main = ({ results, useName=true, title=true, borders=true }: MainProps) => {
  const authStore = useContext(AuthContext);
  const location = useLocation();

  const [showScienceOfWeightLoss, setShowScienceOfWeightLoss] = useState(false);
  const [showHowToAchieve, setShowHowToAchieve] = useState(false);

  const printing = useDetectPrint();

  const ContentWrapper = borders ? Wrapper : BorderlessWrapper

  const caps = (words: string) =>
    words
      .split(' ')
      .map((word: string) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');

  return (
    <ContentWrapper>
      {location.pathname === '/reports/weight-management/' ||
      location.pathname === '/reports/weight-management' ? (
        <Row>
          <Col md={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2>Weight Management</h2>
            </div>
            <p>
              Kia ora{useName ? ' ' + authStore.user.first_name :''},
              <br />
              <br />
              Congratulations on taking your first step towards fat loss!
              Following these recommendations can help you look and feel like
              your best self.
              <br />
              <br />
              At Ingeneous we want to tip the scales in your favor by dialing
              into your genes to learn what is the most effective way for you to
              burn fat.
              <br />
              <br />
              <BoldSpan>
                We believe that following these recommendations are the best way
                to achieve a sustainable optimal weight, and the latest science
                agrees!
              </BoldSpan>
            </p>
          </Col>
          <ImageCol md={4}>
            <img
              src={
                process.env.PUBLIC_URL +
                '/assets/images/recommendations/main-image.png'
              }
              alt="main"
              width={isMobile ? 150 : 300}
            />
          </ImageCol>
        </Row>
      ) : (
        <Row>
          <Col>
            {!title ? '' : <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2>Weight Mgmt Consultation</h2>
            </div>}
            
            <p>
              Kia ora{useName ? ' ' + authStore.user.first_name :''},
              <br />
              <br />
              Weight loss can be challenging, at Ingeneous we want to tip the
              scales in your favour by dialing into your genes to learn what is
              the easiest and most effective way for you to have your body
              looking its best
              <br />
              <br />
              At Ingeneous we want to tip the scales in your favor by dialing
              into your genes to learn what is the most effective way for you to
              burn fat
              <br />
              <br />
              We believe that following these recommendations are the best way
              to achieve a sustainable lifetime optimal weight, and the latest
              science agrees!
            </p>
          </Col>
        </Row>
      )}
      <Spacer height={'30px'} />
      <Alert>
        <span>PROGRAMME CYCLING</span>
        <br />
        <WrapperProgram>
          <p>
            Please follow this{' '}
            {location.pathname === '/reports/weight-management/'
              ? 'Weight Management Plan'
              : 'Body Balance Plan'}{' '}
            for 3 weeks, and then switch to your LifeStyle Plus recommendations
            for at least 3 weeks between periods of fat loss to have the best
            opportunity for success.
          </p>
          <div>
            <img
              src="/assets/images/recommendations/ProgrammeCycling.png"
              alt="cycling"
              width={180}
              height={65}
            />
            <img
              className="repeat-img"
              src="/assets/images/recommendations/ProgrammeCyclingRepeat.png"
              alt="cycling-repeat"
              width={30}
              height={20}
            />
          </div>
        </WrapperProgram>
      </Alert>
      <Spacer height={'30px'} />
      {location.pathname === '/reports/weight-management/' ||
      location.pathname === '/reports/weight-management' ? (
        <div>
          <PrintRow>
            <PrintCol>
              <h3>Understanding Your Report</h3>
              <h5>Science of weight loss</h5>
              <p>
                In a world that is filled with supermarkets, desk jobs,
                restaurants and Netflix - where delicious food can be delivered
                to your door at the click of a button, it is no wonder why
                losing fat is harder than ever and obesity is on the rise. Our
                weight is dependent both on our genes and our environment, but
                genes that have been beneficial for humans in the past and
                helped us survive have now become detrimental in this obesogenic
                (obesity causing) environment. So don&apos;t feel bad if you
                have previously struggled to lose fat; both your genes and the
                environment may be working against you, however Ingeneous hopes
                to give you the upper hand.
              </p>
              {!showScienceOfWeightLoss && !printing && (
                <ReadMore
                  value={showScienceOfWeightLoss}
                  setValue={setShowScienceOfWeightLoss}
                />
              )}
              {(showScienceOfWeightLoss || printing) && (
                <div className="PrintShow">
                  <p>
                    If your fat gain was unintentional, your genes may likely
                    have played a major role. Studies have estimated anywhere
                    from 40-70% of your weight is determined by your genetics.
                    There are many genes that play minor roles in causing a
                    predisposition to weight and fat gain, and this
                    predisposition can come in many forms. Some people may gain
                    fat because they have weaker satiety signals, others may
                    gain fat from temperature influencing their metabolism, or
                    from decreased physical activity. By understanding your
                    genetic weak points we can provide a laser-focus on support
                    and strategies that will work best to help you burn fat
                    based on your genes.
                  </p>
                  <p>
                    These are not drastic unachievable recommendations but tips
                    to incorporate into your life to help achieve a balanced
                    body long term.
                  </p>
                  <p>
                    Incorporate what seems easy for you into your daily life,
                    for example maybe this is having more of your superfood,
                    slightly decreasing your calories and changing the
                    temperature up. Any recommendations that seem more extreme
                    compared to your current lifestyle you can try following
                    strictly for a three week period of initial weight loss.
                    This may include things like taking a supplement, extreme
                    calorie restriction, or increased exercise. After these
                    three weeks go back to following your LifeStyle Plus
                    recommendations for at least 3 weeks before reverting back
                    to this plan to focus on losing more weight. Balancing the
                    time frames you spend in each program with your Ingeneous
                    Certified Practitioner will add greatly to your success long
                    term.
                  </p>
                </div>
              )}
              {showScienceOfWeightLoss && !printing && (
                <ReadLess
                  value={showScienceOfWeightLoss}
                  setValue={setShowScienceOfWeightLoss}
                />
              )}
            </PrintCol>
          </PrintRow>
          <PrintRow>
            <PrintCol>
              <Spacer height={'30px'} />
              <h3>How to achieve your ideal weight</h3>
              <p>
                Everybody is different. We want to help you achieve your body
                goals so you can look and feel your best!
              </p>
              <p>
                We do not want to tell you what your body should look like -
                within a healthy range you can decide for yourself what you
                would like your optimal weight or body fat percentage to be!
                These genetic recommendations are unique to you and following
                them is the most powerful way for you to burn body fat and reach
                your personal goal.
              </p>
              {!showHowToAchieve && !printing && (
                <ReadMore
                  value={showHowToAchieve}
                  setValue={setShowHowToAchieve}
                />
              )}
              {(showHowToAchieve || printing) && (
                <div>
                  <p>
                    Determining a healthy range can be challenging as there are
                    many variables and every person is unique. Keep in mind that
                    a healthy range in general is not necessarily healthy for
                    everyone, and you are the person who knows your body best.
                  </p>
                  <p>
                    {' '}
                    We recommend you pick at least one of the methods below to
                    help guide you in creating a healthy goal and to track your
                    progress.
                  </p>
                  <br />
                  <BoldSpan>BMI</BoldSpan>
                  <br />
                  <p>
                    BMI (Body Mass Index) is a simple way for most adults to
                    determine a healthy weight range. This is not accurate for
                    people with a greater muscle tone as muscle is denser than
                    fat, and a high muscle body composition can result in a
                    higher BMI without a greater health risk. In general a
                    healthy BMI is between 18.5 and 24.9.
                  </p>
                  <p>
                    The formula to calculate BMI is dividing your weight in
                    kilograms by your height in metres squared. This website can
                    help find your healthy weight range:
                    https://www.bmi-calculator.net/metric-bmi-calculator.php
                  </p>
                  <br />
                  <BoldSpan>Waist Circumference</BoldSpan>
                  <br />
                  <p style={{ marginBottom: 0 }}>
                    Waist Circumference is also used as a method to determine
                    your health. Visceral fat around your stomach can be a
                    strong indicator of future health problems associated with
                    obesity including heart disease and type 2 diabetes. For men
                    an ideal waist circumference is under 94cm with greatly
                    increased risk of health issues for measurements above
                    102cm. Women should have a waist circumference of less than
                    80cm and will have greatly increased risk above 89cm.
                  </p>
                  <BoldSpanGreen>Men &lt;102cm </BoldSpanGreen>
                  <br />
                  <BoldSpanGreen>Women &lt;89cm</BoldSpanGreen>
                  <br />
                  <p>
                    To measure your waist circumference, place a tape measure
                    above your hip bones close to your navel, after breathing
                    out measure as close to your skin as possible without
                    compressing it.
                  </p>
                  <br />
                  <BoldSpan>Body Fat Percentage</BoldSpan>
                  <br />
                  <p>
                    A more accurate way to measure your progress and directly
                    determine how much fat you are losing is to calculate your
                    body fat percentage; however, this is more difficult to
                    measure. To determine your body fat percentage skinfold
                    measurements taken by a trained professional, body
                    circumference measurements and body composition scales that
                    use bioelectrical impedance are easily accessible but can be
                    slightly inaccurate. Dual-energy x-ray absorptiometry (DXA)
                    and water displacement are the most reliable measures for
                    body fat percentage but slightly more difficult to come by.
                  </p>
                  <ListGreen>
                    <BoldSpanGreen>Women</BoldSpanGreen>
                    <li>Essential Fat: 12 percent</li>
                    <li>Athletes: 14-20 percent</li>
                    <li>Fitness: 21-24 percent</li>
                  </ListGreen>
                </div>
              )}
              {showHowToAchieve && !printing && (
                <ReadLess
                  value={showHowToAchieve}
                  setValue={setShowHowToAchieve}
                />
              )}
            </PrintCol>
          </PrintRow>
        </div>
      ) : (
        <>
          <ListGroup variant="flush">
            <ListGroup.Item style={{ borderTop: 'none' }}>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Diet</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  Your optimal diet for fat loss is:{' '}
                  <SpanGreen>{caps(results.diet_type ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Exercise</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  Your optimal exercise for fat loss is:{' '}
                  <SpanGreen>{caps(results.exercise_type ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Fasting</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  To lose weight{' '}
                  <SpanGreen>{caps(results.fasting ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Supplement</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  Your most powerful supplement for weight loss is{' '}
                  <SpanGreen>{caps(results.supplement ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Superfood</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  Your superfood is{' '}
                  <SpanGreen>{caps(results.food ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>

          <Spacer height={'30px'} />
          <Subtitle>Predispositions</Subtitle>
          <Spacer height={'20px'} />
          <ListGroup variant="flush">
            <ListGroup.Item style={{ borderTop: 'none' }}>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Athleticism</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  <SpanGreen>{caps(results.athleticism ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Impulse Control</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  <SpanGreen>{caps(results.impulse ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={12} md={3}>
                  <SpanBold>Binge Eating</SpanBold>
                </Col>
                <Col xs={12} md={9}>
                  Risk of Binge Eating:{' '}
                  <SpanGreen>{caps(results.binge_eating ?? '')}</SpanGreen>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          <Col md={1}>&nbsp;</Col>
        </>
      )}
    </ContentWrapper>
  );
};

export default Main;
