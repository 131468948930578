import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import SearchForm from '../components/SearchForm';
import Table from './components/Table';

const SearchWrapper = styled.div`
  h4 {
    color: #707070;
  }
  @media print {
    display: none;
  }
`;

const OffsetCol = styled(Col)`
  margin-top: -110px;
  z-index: 9999;
`;

const OffsetWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #dedede;
`;

const Alert = styled.div`
  color: #e79b43;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  background-color: #f1e5d9;
  border-radius: 5px;

  @media print {
    background-color: #f1e5d9;
    -webkit-print-color-adjust: exact;
  }
`;

const GlobalStyle = createGlobalStyle`
  @page {
    margin: 0;
`;

const PrintableBodyWrapper = styled(Container)`
  @media print {    
    page-break-after: always;
    margin: 0;
    max-width: 100%;

    & > div {
      display: block;
      margin: 0 auto;

      & > div:first-child {
        display: none;
      }

      & > div:nth-child(2) {
        margin: 0 auto;
      }
    }
    .content-wrapper {      
      padding: 0 50px;
      max-width: 100%;
      width: 100%;

      & > div {        
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
        padding-bottom: 10px;
        padding-top: 25px;
      }

      & h2 + p {
        margin-bottom: 5px;
      }
    }
  }
`;

const Methylation = () => {
  const [kitId, setKitId] = useState(localStorage.getItem('consultKitId'));
  const [loading, setLoading] = useState(false)

  // const [kitId, setKitId] = useState('9288740');
  /**
   * Dummy purpose
   *   // const [kitId, setKitId] = useState('9288740');
   */

  const handleSearch = (data) => {
    localStorage.setItem('consultKitId', data);
    setKitId(data);
  };

  if (!kitId) {
    return <Redirect to={'/consult'} />;
  }

  const loadingData = () => {
    return (
      <Col>
        <h2>Loading Results. . .</h2>
      </Col>
    )
  }

  const methylationResult = () => {
    return (
      <Col>
        <h2>Methylation Consult</h2>
        <p>
          To support the Gene.Codes presentation of the
          Methylation data points, this document has been designed
          a prioritised list to give clarity on which one key
          supplement should be recommended to each customer.
        </p>
        <p>
          Starting at COMT, please work systematically down the
          list of genes until the customer meets the requirements
          for the supplement. This is their one methylated
          supplement. Typically, there are to be no further
          supplements required.
        </p>
        <Spacer height={'20px'} className="no-print" />
        <Alert>
          Please only give one recommendation. Below is in
          priority order.
        </Alert>
        <Spacer height={'20px'} className="no-print" />
        <Table kitId={kitId} />
      </Col>
    )
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{user?.display_name}</h3> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>

        <PageBody>
          <GlobalStyle />
          <PrintableBodyWrapper>
            <Row>
              <Col xs={12} md={3}>
                <Spacer height="50px" className="no-print" />
                <SearchWrapper>
                  <h4>Consult Support</h4>
                  <SearchForm handleSearch={handleSearch} query={kitId} handleLoading={setLoading}/>
                </SearchWrapper>
              </Col>
              <OffsetCol md={9} className="content-wrapper">
                <OffsetWrapper>
                  <Row>
                    {loading ? loadingData() : methylationResult()}
                  </Row>
                </OffsetWrapper>
              </OffsetCol>
            </Row>
            <Spacer height={'30px'} />
          </PrintableBodyWrapper>
        </PageBody>
      </Page>
    </>
  );
};

export default Methylation;
